.App {
  text-align: center;
  width: 100vw;
  display: flex;
  justify-content: center;

  .App-header {
    background-color: white;
    min-height: 100vh;
    max-width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;

    .App-logo {
      margin-top: 5vmin;
      height: 30vmin;
      pointer-events: none;
    }

  }

  a, .expand {
    text-decoration: none;
    color: #08F;
  }
  a:hover, .expand:hover {
    color: #5AF;
  }

  .tel {
    display: inline-block;
    margin-top: 3px;
  }

  .details-wrapper {
    margin-top: 24px;
    $fade: opacity .3s;
    .expand {
      display: inline-block;
      cursor: pointer;

      // Fade out when clicked
      opacity: 1;
      transition: $fade;
      &.expanded {
        opacity: 0;
      }
    }
    font-size: calc(10px + 1vmin);
    .details {
      .detail-header {
        font-weight: bold;
        margin: 6px 0px 3px 0px;
      }
      .detail-info {
        margin-top: 0;
      }

      // Fade in
      opacity: 0;
      transition: $fade;
      &.expanded {
        opacity: 1;
      }
    }
  }

}




